import React, { useRef } from "react";
import "./App.css";
import { useEffect, useState } from "react";
import { TbSend } from "react-icons/tb";
import axios from "axios";
import qs from "qs";
import { RiRobot2Line } from "react-icons/ri";


function TextTyper({ text, interval, Markup, onComplete, isTyping }) {
  // console.log("typedtext",text);
  const [typedText, setTypedText] = useState("");
  const typingRender = (text, updater, interval) => {
    let localTypingIndex = 0;
    let localTyping = "";
    // if (text) {
      let printer = setInterval(() => {
        if (!isTyping) {
          clearInterval(printer);
          // console.log("working");
          return;
        }
        if (localTypingIndex < text.length) {
          updater((localTyping += text[localTypingIndex]));
          localTypingIndex += 1;
        } else {
          localTypingIndex = 0;
          localTyping = "";
          clearInterval(printer);
          // console.log(onComplete);
          // if (onComplete) {
            onComplete();
            // console.log("working");
          // }
          //  return setter && setter(value)
        }
      }, interval);
    // }
  };
  useEffect(() => {

    typingRender(text, setTypedText, interval);

  }, [text,interval]);

  return (
    <Markup>
      {typedText}
    </Markup>
  );
}
const MultiLineTyping = ({ lines, isTyping }) => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  // console.log(lines);
  useEffect(() => {
    if (currentLineIndex < lines.length) {
      // When a line finishes, we increment the currentLineIndex to render the next line
      setCurrentLineIndex(currentLineIndex);
    }
    // console.log(currentLineIndex);
  }, [currentLineIndex, lines]);

  const handleLineComplete = () => {
    // console.log("handleLineComplete");
    // Move to the next line once the current line is fully printed
    setCurrentLineIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <>
      {lines.map(
        (line, index) =>
          index <= currentLineIndex && ( // Only render lines that should be printed
            <TextTyper
              key={index}
              text={line}
              interval={10}
              Markup={"p"}
              onComplete={handleLineComplete}
              isTyping={isTyping}
            />

            // <TypingComponent
            //   key={index}
            //   isTyping={isTyping}
            //   message={line}
            //   onComplete={
            //     index === currentLineIndex ? handleLineComplete : null
            //   }
            // />
          )
      )}
    </>
  );
};
function App() {
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([
    // { user: "me", message: "hey" },
    // { user: "gpt", message: "Hello! It seems like you're interested in the Foundations of Programming course at Timothy Christian Schools. This course is available for students in grades 9-12 and requires a prerequisite of either T Digital Information Technology or Computer Applications. It's also recommended that students complete this course prior to finishing Algebra 1. \n\nThe course can be taken for either 1 or 2 semesters, with a transfer credit of 0.5 for 1 semester and 1.0 for 2 semesters. Please note that this does not impact the TC GPA. The fee for 1 semester is $515, and for 2 semesters it's $655, payable directly to Sevenstar. \n\nThe TC business office will reimburse half the amount to the parent upon successful completion of the course with a grade of “C” or better. Please remember to notify the TCHS registrar immediately about this registration. \n\nThis course falls under the S.T.E.M. category, specifically under Science, Technology, Engineering, & Mathematics. It's also part of the RENEW program and counts as 0.5 credits towards Computer Applications. \n\nLet me know if you have any other questions!\n\nDo you have any other questions about Timothy Christian Schools?" },
  ]);
  const [error, setError] = useState(null); // Error state
  const [loader, serLoader] = useState(false);
  const divRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const textareaRef = useRef(null);
  useEffect(() => {
    const { current } = divRef;
    current.scrollTop = current.scrollHeight;
  }, [divRef]);

  useEffect(() => {
    // Adjust textarea height based on scroll height to accommodate new lines
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [input]);

  const handleSubmit = async (e) => {
    setIsTyping(false);
    e.preventDefault();
    scrollBottom();
    if (input.trim() === "") {
      // console.log("input is empty");
    } else {
      let data = qs.stringify({
        query: input,
      });
      let chatlogNew = [...chatLog, { user: "me", message: `${input}` }];
      setInput("");
      setChatLog(chatlogNew);
      serLoader(true);
      setError(null);
      // Axios request configuration
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_PRODUCTION_API_URL}/ask`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      // Make the POST request using axios
      axios
        .request(config)
        .then((response) => {
          setIsTyping(true);
          // Handle the response data
          // console.log(JSON.stringify(response.data));
          // console.log(JSON.stringify(response.data.response));
          const apiResponse = JSON.stringify(response.data.response);
          const formattedText = apiResponse
            .replace(/\n/g, "")
            .replace(/^"|"$/g, "")
            .replace(/\\"/g, '"'); // Replace \" with just "

          setChatLog([
            ...chatlogNew,
            { user: "gpt", message: `${formattedText}` },
          ]);

          serLoader(false);
          scrollBottom();
        })
        .catch((error) => {
          setIsTyping(false);
          // console.error(error);
          setError(
            "An error occurred while processing your request. Please try again"
          ); // Set error
          serLoader(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent new line

      // if(!isTyping){
      handleSubmit(e); // Call submit function

      // }
    }
  };

  const scrollBottom = () => {
    const { current } = divRef;
    current.scrollTop = current.scrollHeight;
  };

  return (
    <div className="App">
      <section className="chatbox">
        {chatLog.length < 1 && (
          <div className="intro_section">
            <img src="/static/timtest.png" alt="" />
            <h3>Achilles AI Assistant</h3>
            <p>A parent's guide to all things Achilles. </p>
          </div>
        )}

        <div ref={divRef} className="chat_log">
          {chatLog.map((message, index) => (
            <ChatMessage key={index} message={message} isTyping={isTyping} />
          ))}

          {loader && (
            <div className={`chat_message chatgpt`}>
              <div className="chat_message_center chatgpt loader">
                <div className={`avatar chatgpt`}>
                  <RiRobot2Line color="#000" size={25} />
                </div>
                <div className={`message chatgpt loader`}>
                  <img src="/static/response-loader.svg" alt="" width={100} />
                </div>
              </div>
            </div>
          )}
          {error && (
            <div className={`chat_message chatgpt`}>
              <div className="chat_message_center chatgpt loader">
                <div className={`avatar chatgpt`}>
                  <RiRobot2Line color="#000" size={25} />
                </div>
                <div className={`message chatgpt loader`}>{error}</div>
              </div>
            </div>
          )}
        </div>

        <div className="chat_input_holder">
          <form onSubmit={handleSubmit} className="input_field_container">
            <textarea
              rows={1}
              ref={textareaRef}
              onKeyDown={handleKeyDown} // Trigger on "Enter"
              placeholder="Ask a question"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="chat_input"
            />
            <div className="send_icon">
              <TbSend color="#5F606F" size={20} onClick={handleSubmit} />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

const ChatMessage = ({ message, isTyping }) => {
  const lines = message.message.split("\\n");
  
  return (
    <>
      <div className={`chat_message ${message.user === "gpt" && "chatgpt"}`}>
        <div className="chat_message_center ">
          <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
            {message.user === "gpt" && <RiRobot2Line color="#000" size={25} />}
          </div>
          <div className={`message ${message.user === "gpt" && "chatgpt"}`}>
            {message.user !== "gpt" ? (
              lines.map((line, index) => <p key={index}>{line}</p>)
            ) : (
              <MultiLineTyping lines={lines} isTyping={isTyping} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
